var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm._v(
            " " +
              _vm._s(_vm.showTopBottom) +
              " " +
              _vm._s(_vm.showPerPage) +
              " " +
              _vm._s(_vm.title) +
              " "
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.source == "spotify",
                  expression: "source == 'spotify'"
                }
              ],
              staticStyle: { float: "right" }
            },
            [
              _vm._v(" Bron: Spotify "),
              _c("i", { staticClass: "fa fa-spotify" })
            ]
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.subTitle,
                  expression: "subTitle"
                }
              ],
              staticStyle: { float: "right" }
            },
            [_vm._v(" " + _vm._s(_vm.subTitle) + " ")]
          ),
          _c("b-spinner", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.trendsLoading,
                expression: "trendsLoading"
              }
            ],
            attrs: { small: "", label: "Spinning" }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.countTotal == 0,
              expression: "countTotal == 0"
            }
          ]
        },
        [_vm._v(" Geen gegevens beschikbaar ")]
      ),
      _c("b-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.countTotal > 0,
            expression: "countTotal > 0"
          }
        ],
        attrs: { striped: "", small: "", fields: _vm.fields, items: _vm.items },
        scopedSlots: _vm._u([
          {
            key: "head(title)",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.columnTitle) + " ")]
            },
            proxy: true
          },
          {
            key: "cell(title)",
            fn: function(row) {
              return [
                _vm.canSelect == true
                  ? _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.$emit("item-click", {
                              type: _vm.aggName,
                              item: row.item
                            })
                          }
                        }
                      },
                      [_vm._v(_vm._s(row.item.title))]
                    )
                  : _vm._e(),
                _vm.canSelect == false
                  ? _c("span", [_vm._v(_vm._s(row.item.title))])
                  : _vm._e()
              ]
            }
          },
          {
            key: "cell(count)",
            fn: function(row) {
              return [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "white-space": "nowrap",
                      "font-family": "'Roboto Mono', monospace"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm._f("numberFormat")(
                          row.item.count,
                          _vm.absDecimals,
                          _vm.numberLocale
                        )
                      )
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "cell(percentage)",
            fn: function(row) {
              return [
                _c(
                  "span",
                  {
                    staticStyle: { "font-family": "'Roboto Mono', monospace" }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("numberFormat")(row.item.percentage)) + " %"
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }