import { render, staticRenderFns } from "./RoyaltyAnalyticsList.vue?vue&type=template&id=72c41df0&scoped=true&"
import script from "./RoyaltyAnalyticsList.vue?vue&type=script&lang=js&"
export * from "./RoyaltyAnalyticsList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72c41df0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ebas/Library/CloudStorage/Dropbox/ebDev/Workspace/the-source/boep-ce-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('72c41df0')) {
      api.createRecord('72c41df0', component.options)
    } else {
      api.reload('72c41df0', component.options)
    }
    module.hot.accept("./RoyaltyAnalyticsList.vue?vue&type=template&id=72c41df0&scoped=true&", function () {
      api.rerender('72c41df0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/RoyaltyAnalyticsList.vue"
export default component.exports