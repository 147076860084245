export default {
    methods: {
      getTrendData(filter, aggName, perPage) {
        return new Promise((resolve, reject) => {
            if (filter.timerange == 'custom' && (
                filter.dateFrom == '' || filter.dateTo == ''))
            return reject();

            this.$http
                .post('analytics/trend-data', {
                    filter: filter,
                    aggName: aggName,
                    perPage: perPage,
                })
                .then(response => {
                    var data = response.data
                    resolve(data)
                })
                .catch(response => {
                    reject(response.data)
                })

        })
      },
      getRoyaltyAnalyticsData(filter, aggName, target, perPage, sortDirection) {
        return new Promise((resolve, reject) => {
            if (filter.timerange == 'custom' && (
                filter.dateFrom == '' || filter.dateTo == ''))
            return reject();

            this.$http
                .post('analytics/royalty-data', {
                    filter: filter,
                    aggName: aggName,
                    target: target,
                    perPage: perPage,
                    sortDirection: sortDirection,
                })
                .then(response => {
                    var data = response.data
                    resolve(data)
                })
        })
      },
      getCountryList() {
        return new Promise((resolve) => {
            this.$http
                .post('getCountryList', {

                })
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      },
      getTaskList() {
        return new Promise((resolve) => {
            this.$http
                .post('getTaskList', {

                })
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      },
      getStatementReports(accountingPeriod = null) {
        return new Promise((resolve) => {
            this.$http
                .get('getStatementReports', {params: {
                  accountingPeriod: accountingPeriod
                }})
                .then(response => {
                    var data = response.data.result
                    resolve(data)
                })
        })
      }
    }
  };